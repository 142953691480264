import React, {useEffect, useRef} from "react"
import {
    AboutWrapper,
    FirstAbout, FirstAboutTitle,
    FirstAboutWrapper, HeaderOne,
    Paragraph,
    SecondAbout, SecondParagraph,
} from "./home-about.style"
import Button from "../button/button.component";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {StyledDivider} from "../svg/svg.component";
import {gsap, Power3, ScrollTrigger} from "gsap/all";

export const paragraphAnim = (elem) => {
    gsap.from(elem, {
        scrollTrigger: {
            trigger: elem,
            start: 'top bottom',
        }, duration: 1, delay: .5, opacity: 0, y: 50, ease: Power3.easeIn})
}

const HomeAbout = () => {
    let aboutText = useRef(null);
    let aboutParagraph = useRef(null);
    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        gsap.from(aboutText.children, {
            scrollTrigger: {
                trigger: aboutText,
                start: 'top bottom',
        }, duration: .8, delay: .5, x: -100, stagger: .8, opacity: 0, ease: Power3.easeIn})
        paragraphAnim(aboutParagraph);
    }, [])


    const data = useStaticQuery(graphql`
  query HomeAboutQuery {
  image: file(relativePath: {eq: "plane2.jpg"}) {
    id
    childImageSharp {
      fluid(maxWidth: 5000) {
      ...GatsbyImageSharpFluid
      }
    }
  } 
}
 `)

    return (
        <AboutWrapper>
            <FirstAboutWrapper>
                <FirstAbout ref={el => aboutText = el}>
                    <FirstAboutTitle>We are Qube pcs</FirstAboutTitle>
                    <SecondParagraph> QUBE PCS is an internationally experienced professional services company that
                        supports global
                        organisations that wish to invest in, own and operate assets. We are a prominent provider of
                        professional consultancy services to private and public clients within the sectors of
                        construction, aviation, infrastructure, water & utilities, environmental development, property
                        markets and football management.</SecondParagraph>
                    <Button link='/about' type='secondary'>Learn More</Button>
                </FirstAbout>
            </FirstAboutWrapper>

            <SecondAbout>
                <Img fluid={data.image.childImageSharp.fluid}/>
                <div style={{padding: '50px 5vw'}}>
                    <HeaderOne style={{textAlign: 'center'}}>Welcome</HeaderOne>
                    <StyledDivider/>
                    <Paragraph ref={el => aboutParagraph = el}>
                        We work closely with clients, surveyors, architects, contractors and consultants throughout all
                        phases of design development and building processes to ensure projects are delivered with the
                        most effective
                        outcome.
                    </Paragraph>
                </div>
            </SecondAbout>
        </AboutWrapper>
    )
}

export default HomeAbout
