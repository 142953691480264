import styled from "styled-components"
import {palette, typeScale} from "../../utils/elements";




export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${palette.grayish};
  padding: 100px 5%;
`;

export const MainContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  >div{
  width: 90vw !important;
  }
  @media only screen and (min-width: 900px){
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 30px;
  >div{
  width: 30vw !important;
  }
  }
`;

export const OneLane = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  &:last-of-type{
    @media only screen and (max-width: 900px){
      justify-content: space-around;
      max-width: 400px;
    }
  } 
  &:last-of-type{
    @media only screen and (min-width: 900px){
      justify-content: space-around;
      flex-direction: row;
    }
  } 
  a{
  font-size: ${typeScale.subtext};
  }

  &:nth-of-type(2){
    text-align: center;
  } 
   
  &:nth-of-type(3){
    text-align: right;
    svg{
    left: 100%;
    top: 0;
    &:first-of-type{
    margin-top: 50px;
    }
    }
  }
  a{
  color: black;
  }
  >div{
  padding-top: 50px;
  position: relative;
   @media only screen and (max-width: 600px) {
  .fa-envelope{
    display: none;
  }
  }
  >svg{
  top: 70%;
  }
  }
  }
  p{
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  }
  svg{
  position: absolute;
  left: -30px;
  top: 30%;
  color: ${palette.byzantium};
  }
  
  @media only screen and (min-width: 900px){
   align-items: initial;
   &:nth-of-type(2){
    svg{
    left: 15%;
    }
  }
  }
`;

export const Location = styled.div`

`;

export const SocialMedia = styled.div`
  width: 50px;
  height: 50px;
  margin-left: auto;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms ease-in-out;
  cursor: pointer;
  &:hover{
  background-color: ${palette.byzantium};
  }
  svg{
  color: white;
  position: relative !important;
  top: auto !important;
  left: auto !important;
  margin: 0 !important;
  font-size: 1.3rem;
  }
`;

export const CopyrightSection = styled.div`
  padding: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .9);
  p{
  margin: 0;
  }
`;



