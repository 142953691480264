import React, {useEffect, useRef} from "react"
import {
    CopyrightSection,
    FooterWrapper, Location, MainContactWrapper, OneLane, SocialMedia,
} from "./footer.style"
import {Container, Paragraph, SecondParagraph, Title} from "../home-about/home-about.style"
import {StyledDivider} from "../svg/svg.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {RawButton} from "../button/button.style";
import {paragraphAnim} from "../home-about/home-about.component";


const Footer = () => {
    let footerPara = useRef(null);

    useEffect(() => {
        paragraphAnim(footerPara);
    })

    return (
        <>
            <FooterWrapper>
                <Title>Contact</Title>
                <Paragraph style={{maxWidth: '550px'}} ref={el => footerPara = el}>We are ready to connect with you. You
                    can contact us at any time. We are ready to serve your needs.</Paragraph>
                <Container style={{maxWidth: '550px', width: '50%', margin: '50px 0'}}><StyledDivider/></Container>
                <MainContactWrapper>
                    <OneLane>
                        {/*<Container>*/}
                        {/*    <Location>*/}
                        {/*        <Paragraph> <FontAwesomeIcon icon={faLocationArrow}/>TN 149</Paragraph>*/}
                        {/*        <Paragraph>Teshie Nungua Estates</Paragraph>*/}
                        {/*        <Paragraph>Accra, Ghana</Paragraph>*/}
                        {/*    </Location>*/}
                        {/*</Container>*/}
                        <Container>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <a href="mailto:info@qubepcs.com" target='_blank'
                               rel="noreferrer"><RawButton>info@qubepcs.com</RawButton></a>
                        </Container>
                    </OneLane>
                    <OneLane>
                        <Container>
                            <Location>
                                <Paragraph> <FontAwesomeIcon icon={faPhone}/>
                                    (+233) 02002 16915</Paragraph>
                                <Paragraph>(+44) 07342 834164</Paragraph>
                            </Location>
                        </Container>
                        {/*<Container>*/}
                        {/*    <FontAwesomeIcon icon={faBusinessTime}/>*/}
                        {/*    <Paragraph>M-F: 8 AM - 3 PM</Paragraph>*/}
                        {/*    <Paragraph>Sa: 8 AM - 12 PM</Paragraph>*/}
                        {/*    <Paragraph>Su: Closed</Paragraph>*/}
                        {/*</Container>*/}
                    </OneLane>
                    <OneLane>
                        <Container>
                            <a href="https://web.facebook.com/QUBEpcs-100431671781675/?ref=page_internal&path=%2FQUBEpcs-100431671781675%2F"
                               target='_blank'
                               rel="noreferrer">
                                <SocialMedia>
                                    <FontAwesomeIcon icon={faFacebookF}/>
                                </SocialMedia>
                            </a>
                        </Container>
                        <Container>
                            <a href="https://twitter.com/PcsQube" target='_blank'
                               rel="noreferrer"><SocialMedia>
                                <FontAwesomeIcon icon={faTwitter}/>
                            </SocialMedia></a>
                        </Container>
                        <Container>
                            <a href="https://www.instagram.com/qubepcs/" target='_blank'
                               rel="noreferrer">
                                <SocialMedia>
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </SocialMedia>
                            </a>
                        </Container>
                    </OneLane>
                </MainContactWrapper>
            </FooterWrapper>
            <CopyrightSection><SecondParagraph>© Copyright 2020. All rights
                reserved.</SecondParagraph></CopyrightSection>
        </>
    )
}

export default Footer