import PropTypes from "prop-types"
import React, {useState} from "react"
import {HeaderWrapper, MenuBar, MenuButton, Nav, NavItem} from "./header.style"
import {graphql, useStaticQuery} from "gatsby"
import Img from 'gatsby-image';
import { Link } from "gatsby"


const HeaderComponent = () => {
    const [toggle, setToggle] = useState(false);

    const toggleMenu = () => {
        setToggle(!toggle);
    }

    const data = useStaticQuery(graphql`
  query Images {
  image: file(relativePath: {eq: "logo.png"}) {
    id
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}  
  `)


    return (
        <HeaderWrapper>
            <Link to='/'><Img fluid={data.image.childImageSharp.fluid}  alt='logo'/></Link>
            <Nav show={toggle}>
                <NavItem to='/' onClick={toggleMenu}>Home</NavItem>
                <NavItem to='/services' onClick={toggleMenu}>Services</NavItem>
                <NavItem to='/#clients' onClick={toggleMenu}>Clients</NavItem>
                <NavItem to='/about' onClick={toggleMenu}>About Us</NavItem>
                <NavItem to='/sports' onClick={toggleMenu}>Sports Management</NavItem>
            </Nav>
            <MenuButton aria-label="Toggle menu" aria-expanded={toggle} open={toggle} onClick={toggleMenu}>
                <MenuBar/>
                <MenuBar style={{margin: '7px 0', width: '20px'}}/>
                <MenuBar/>
            </MenuButton>
        </HeaderWrapper>

    )
}


HeaderComponent.propTypes = {
    siteTitle: PropTypes.string,
}

HeaderComponent.defaultProps = {
    siteTitle: ``,
}

export default HeaderComponent
